<template>
  <div>
    <!-- <div class="layer2">
      <div class="field">
        <div class="control">
          <InformasiPosBaru @posisi="handleClick" />
        </div>
      </div>
    </div> -->
    <div class="pengumuman">
      <div class="field">
        <div class="control">
          <ModalPengumuman />
        </div>
      </div>
    </div>

    <div class="page-content-wrapper">
      <!-- Content -->
      <div class="dashboard-map-wrapper">
        <div class="dashboard-map-wrapper-inner">
          <div id="mapbox-1" class="map">
            <!-- DISINI MAPSnya -->
            <HomeMapBaru :position="position" />
            <!-- END MAPS -->
          </div>
          <div class="content-section">
            <div class="content-section-header">
              <div class="toolbar"></div>
            </div>
            <div class="content-section-body">
              <!--Title-->
              <h2 class="titles">Informasi Pos Duga Air Otomatis</h2>
              <h4 class="text">Data Per 5 menit</h4>
              <!-- <button v-on:click="show = !show">
            Toggle
          </button> -->
              <!--Map Box-->
              <div
                class="content"
                v-for="(pos, index) in posList"
                :lat-lng="[pos.lat, pos.lng]"
                :key="index"
              >
                <div class="box map-box">
                  <div
                    class="map-box-place">
                    <!-- <div v-if="show"
                  class="map-box-place"
                  @click="remove(index)"
                > -->

                    <div class="map-box-header">
                      <div class="media-flex-center">
                        <div class="flex-meta">
                          <span>{{ pos.namaPos }}</span>
                          <span>{{ pos.lokasiPos }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="map-box-body">
                      <table class="table">
                        <tbody>
                          <tr v-if="pos.das">
                            <td>Das</td>
                            <td>:</td>
                            <td>{{ pos.das ? pos.das : "" }}</td>
                          </tr>
                          <tr v-if="pos.sungai">
                            <td>Sungai</td>
                            <td>:</td>
                            <td>{{ pos.sungai ? pos.sungai : "" }}</td>
                          </tr>
                          <tr>
                            <td>Waktu</td>
                            <td>:</td>
                            <td>{{ pos.timestamp ? pos.timestamp : "" }}</td>
                          </tr>
                          <tr>
                            <td>TMA</td>
                            <td>:</td>
                            <td>{{ pos.tma ? pos.tma : "-" }} cm</td>
                          </tr>
                          <tr>
                            <td>Status</td>
                            <td>:</td>
                            <td v-if="pos.status === 'AMAN'">
                              <span class="tag is-success is-square">
                                {{ pos.status ? pos.status : "AMAN" }}
                              </span>
                            </td>
                            <td v-else-if="pos.status === 'SIAGA'">
                              <span class="tag is-warning is-square">
                                {{ pos.status ? pos.status : "SIAGA" }}
                              </span>
                            </td>
                            <td v-else-if="pos.status === 'AWAS'">
                              <span class="tag is-danger is-square">
                                {{ pos.status ? pos.status : "AWAS" }}
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <!-- <span class="content-section-title">
                        (* Klik 2x untuk melihat posisi pada peta)
                      </span> -->
                    </div>
                  </div>
                </div>
                <!--Map Box-->
              </div>
              <!-- END MAP BOX -->
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import HomeMapBaru from "./HomeMapBaru.vue";
// import InformasiPosBaru from "./InformasiPosBaru.vue";
import ModalPengumuman from "./ModalPengumuman.vue";
let sseClient;
export default {
  name: "Landing",
  components: {
    HomeMapBaru,
    // InformasiPosBaru,
    ModalPengumuman,
  },
  data() {
    return {
      position: [],
      status: "NORMAL",
    };
  },
  created() {
    this.fetchLandingMonitor().then(() => {
      this.loading = false;
    });

    this.loadSubcribe();
  },
  computed: {
    ...mapState({
      kadasters: (state) => state.landingKadaster.items,
      monitorPda: (state) => state.monitorPda.items,
    }),
    posList() {
      return this.monitorPda.slice().reverse();
    },
  },
  methods: {
    ...mapActions("landingKadaster", ["fetchLandingKadaster"]),
    ...mapActions("monitorPda", ["fetchLandingMonitor"]),
    handleClick: function (event) {
      this.position = event;
    },
    loadSubcribe() {
      sseClient = this.$sse.create({
        url: "/socket/sse/subscribe",
        format: "json",
        withCredentials: false,
        polyfill: true,
      });

      // Catch any errors (ie. lost connections, etc.)
      sseClient.on("error", (e) => {
        console.error("lost connection or failed to parse!", e);

        // If this error is due to an unexpected disconnection, EventSource will
        // automatically attempt to reconnect indefinitely. You will _not_ need to
        // re-add your handlers.
      });

      // Handle messages without a specific event
      sseClient.on("message", this.message);

      // Handle 'chat' messages
      sseClient.on("pdaMonitoring", (msg) => {
        this.$store.dispatch("monitorPda/dispatchPdaMonitor", msg);
        this.$store.dispatch("monitorPda/postPdaMonitor", msg);
      });

      sseClient
        .connect()
        .then((sse) => {
          console.log("Data Realtime connected!");

          // Unsubscribes from event-less messages after 7 seconds
          setTimeout(() => {
            sseClient.off("message", this.message);
            // console.log("Stopped listening to event-less messages!");
          }, 7000);

          // Unsubscribes from chat messages after 14 seconds
          setTimeout(() => {
            sse.off("pdaMonitoring", this.data);
            // console.log("Stopped listening to Pda Monitoring messages!");
          }, 14000);
        })
        .catch((err) => {
          // When this error is caught, it means the initial connection to the
          // events server failed.  No automatic attempts to reconnect will be made.
          console.error("Failed to connect to server", err);
        });
    },
  },
  beforeDestroy() {
    // Make sure to close the connection with the events server
    // when the component is destroyed, or we'll have ghost connections!
    sseClient.disconnect();

    // Alternatively, we could have added the `sse: { cleanup: true }` option to our component,
    // and the SSEManager would have automatically disconnected during beforeDestroy.
  },
};
</script>

<style scoped>
.map {
  position: relative;
  width: 100%;
  z-index: 1;
}
/* .pengumuman
{
  position: absolute;
  z-index: 99;
} */

.layer2 {
  position: absolute;
  margin-top: 155px;
  right: 0px;
  z-index: 2;
}

.content-section {
  visibility: hidden;
}

.dashboard-map-wrapper-inner {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.titles {
  position: relative;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: bold;
}

.map-box {
  margin-left: 45%;
  width: 100%;
  transform: translateX(-100%);
  opacity: 0;
  animation: slide-in-anim 5s ease-out forwards;
}

@keyframes slide-in-anim {
  20% {
    opacity: 0;
  }
  60% {
    transform: translateX(-45%);
  }
  75% {
    transform: translateX(-52%);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%);
  }
}

@keyframes slide-out-anim {
  20% {
    opacity: 1;
    transform: translateX(-55%);
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 0;
    transform: translateX(200%);
  }
}

@media screen and (max-width: 1365px) {
  .layer2 {
    overflow: hidden;
  }
}

@media screen and (max-width: 1024px) {
  .content-section {
    visibility: visible;
    margin-top: 0px;
    margin-bottom: 20px;
  }

  .layer2 {
    visibility: hidden;
    overflow: hidden;
  }

  .content {
    margin-left: 40px;
  }

  .box {
    width: 100%;
  }

  .titles {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
  }

  .text {
    font-size: 12px;
    color: gray;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .dashboard-map-wrapper-inner {
    overflow: scroll;
    overflow-x: hidden;
  }
}

@media (max-width: 769px) {
  .content-section {
    visibility: visible;
    margin-top: -60px;
    margin-bottom: 10px;
  }
  .layer2 {
    visibility: hidden;
    overflow: hidden;
  }

  .content {
    margin-left: 15px;
  }

  .titles {
    margin-top: 0px;
    margin-bottom: 20px;
    margin-left: 10px;
    font-size: 14px;
    font-weight: bold;
  }

  .text {
    font-size: 12px;
    color: gray;
    margin-top: 0px;
    margin-bottom: 10px;
    margin-left: 10px;
  }

  .dashboard-map-wrapper-inner {
    overflow: scroll;
    overflow-x: hidden;
  }
}
</style>
