<template>
  <div class="">
    <section>
      <div class="nav">
        <div class="linknav">
          <LinkNavigationBaru />
        </div>
      </div>
    </section>

    <section>
      <div class="informasi">
        <div class="infopos">
          <InformasiPosBaru @posisi="handleClick" />
        </div>
      </div>
    </section>

    <section class="animasi-pencarian">
      <div class="search-box" :class="{ show: showSidebar }">
        <div class="autocom" :class="{ show: showSidebar }">
          <b-field class="medium" :class="{ show: showSidebar }">
            <b-autocomplete
              rounded
              v-model="namaPos"
              placeholder="Cari Pos"
              :keep-first="keepFirst"
              :open-on-focus="openOnFocus"
              :data="filteredPos"
              field="namaPos"
              @select="(option) => (selected = option)"
              @keydown.native.enter="cariData"
            >
              <template #empty>
                <div class="drop">Nama Pos Tidak Ditemukan {{ namaPos }}</div>
              </template>
            </b-autocomplete>
          </b-field>

          <button
            @click="cariData"
            type="submit"
            class="button is-focused caridata"
          >
            <span class="icon is-left iconcari">
              <i class="fas fa-search"></i>
            </span>
          </button>
        </div>

        <div class="search-btn button is-focused" @click="showNav">
          <i class="fas fa-search"></i>
        </div>
      </div>
    </section>

    <section>
      <l-map
        class="map"
        :zoom="zoom"
        :center="center"
        :options="{ zoomControl: false }"
      >
        <l-control-zoom class="zoom" position="topright"></l-control-zoom>
        <!-- <l-control-layers position="topright"></l-control-layers>
        <l-tile-layer
          v-for="tileProvider in tileProviders"
          :key="tileProvider.name"
          :name="tileProvider.name"
          :visible="tileProvider.visible"
          :url="tileProvider.url"
          :attribution="tileProvider.attribution"
          layer-type="base"
        /> -->
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>

        <l-control class="layer-control" position="bottomright">
          <div class="layer1">
            <div class="cards">
              <span class="title1">Informasi Peta </span>
            </div>
            <div class="field">
              <div class="control">
                <div class="lcontrol">
                  <div class="field">
                    <div class="">
                      <div class="biru"></div>
                    </div>

                    <span>Pos Duga Air</span>
                  </div>

                  <div class="field">
                    <div class="">
                      <div class="hijau"></div>
                    </div>

                    <span>Pos Curah Hujan</span>
                  </div>

                  <div class="field">
                    <div class="sungai"></div>
                    <span>Sungai</span>
                  </div>

                  <div class="field">
                    <div class="merah"></div>
                    <span>Banjir</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </l-control>

        <l-geo-json :geojson="wilKer" :options-style="styleWilker" />
        <l-geo-json :geojson="sungaiUtama" :options-style="styleCisanggarung" />
        <div v-for="(forecastFile, index) in forecastFiles" :key="index">
          <l-geo-json :geojson="forecastFile" :options-style="styleForecast" />
        </div>

        <div v-for="(clusterFile, index) in clusterFiles" :key="index">
          <l-geo-json
            :geojson="clusterFile.uratSungai"
            :options-style="styleJangkelok"
          />
        </div>

        <l-layer-group>
          <l-circle-marker
            v-for="(kadaster, index) in pos"
            :lat-lng="[kadaster.lat, kadaster.lng]"
            :key="index"
            :radius="circle.radius"
            :color="
              kadaster.isManualPda || kadaster.isTelemetryPda
                ? circle.color
                : kadaster.isManualPch || kadaster.isTelemetryPch
                ? pch.color
                : defaults.color
            "
            :fillColor="
              kadaster.isManualPda || kadaster.isTelemetryPda
                ? circle.fillColor
                : kadaster.isManualPch || kadaster.isTelemetryPch
                ? pch.fillColor
                : defaults.fillColor
            "
            :fillOpacity="circle.fillOpacity"
          >
            <!-- <l-marker
           v-for="(kadaster, index) in pos"
          :lat-lng="[kadaster.lat, kadaster.lng]" 
          :key="index"  
          :icon="getIcon(kadaster)"
          > -->

            <l-tooltip
              :options="{ interactive: true }"
              :content="kadaster.namaPos"
            >
            </l-tooltip>
            <l-popup
              :options="{
                closeButton: false,
                autoClose: true,
                openPopup: true,
              }"
            >
              <table class="table">
                <tbody>
                  <tr>
                    <td>Nama Pos</td>
                    <td>:</td>
                    <td>{{ kadaster.namaPos }}</td>
                  </tr>

                  <tr>
                    <td>Lokasi</td>
                    <td>:</td>
                    <td>{{ kadaster.lokasiPos }}</td>
                  </tr>
                  <tr>
                    <td>Jenis</td>
                    <td>:</td>
                    <td>
                      <ul>
                        <li>{{ kadaster.isManualPda ? "PDA Manual" : "" }}</li>
                        <li>{{ kadaster.isManualPch ? "PCH Manual" : "" }}</li>
                        <li>
                          {{ kadaster.isTelemetryPda ? "PDA Otomatis" : "" }}
                        </li>
                        <li>
                          {{ kadaster.isTelemetryPch ? "PCH Otomatis" : "" }}
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="tombol" v-if="kadaster.registerId !== null">
                <button
                  class="button lihat"
                  @click="handleEmit(kadaster.registerId)"
                  type="is-success"
                  outlined
                >
                  <i class="fas fa-search"></i>
                  <span class="title2"> Lihat Data </span>
                </button>
              </div>
            </l-popup>
            <!-- </l-marker>   -->
          </l-circle-marker>
          <!-- <l-marker
           ref="marker" 
          :lat-lng="[newLt, newLng]"  
          :icon="getIcon(jenisPos)"
          > -->
          <l-circle-marker
            ref="marker"
            :lat-lng="[newLt, newLng]"
            :radius="circle.radius"
            :color="circle.color"
            :fillColor="circle.fillColor"
            :fillOpacity="circle.fillOpacity"
          >
            <l-tooltip :content="newLoc" :options="{ interactive: true }">
            </l-tooltip>
            <!-- </l-marker> -->
          </l-circle-marker>
        </l-layer-group>
      </l-map>
    </section>
  </div>
</template>

<script>
let sseClient;
// var sond = { soundurl : '/files/navy_alert.mp3' } 
import { mapActions, mapState } from "vuex";
import {
  LMap,
  LTileLayer,
  LControlZoom,
  LGeoJson,
  LPopup,
  LControl,
  LCircleMarker,
} from "vue2-leaflet";
import "leaflet/dist/leaflet.css";
import { icon } from "leaflet";
import LinkNavigationBaru from "../navigation/LinkNavigationBaru.vue";
import InformasiPosBaru from "../landing/InformasiPosBaru.vue";

export default {
  name: "HomeMap",
  components: {
    LMap,
    LTileLayer,
    LControlZoom,
    LControl,
    LGeoJson,
    LPopup,
    LCircleMarker,
    LinkNavigationBaru,
    InformasiPosBaru,
  },
  // props: {
  //   position: {
  //     type: Array,
  //     required: true,
  //   },
  // },
  data() {
    return {
      zoom: 10,
      center: [-6.7942, 108.2799],
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors - Unit Hidrologi dan Kualitas Air - BBWS Cimanuk Cisanggarung © 2024',
      // tileProviders: [
      //   {
      //     name: "OpenStreetMap",
      //     visible: true,
      //     attribution:
      //       ' <a href="http://osm.org/copyright">OpenStreetMap</a> contributors - BBWS Cimanuk Cisanggarung © 2024',
      //     url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      //   },
      //   {
      //     name: "Satelite Map View",
      //     visible: false,
      //     url: "https://api.maptiler.com/maps/hybrid/{z}/{x}/{y}.jpg?key=U35hVmKX1osCNEu7XZjz",
      //     attribution:
      //       'Map data: <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors - BBWS Cimanuk Cisanggarung © 2024',
      //   },
      // ],
      markerLatLng: [-6.73003, 108.54273],
      icon: icon({
        iconUrl: require("@/assets/blue-marker-10x10.svg"),
        iconSize: [32, 37],
        iconAnchor: [16, 37],
      }),
      fillColor: "#d63031",
      newLoc: "",
      newLt: 0,
      newLng: 0,
      locations: [
        {
          name: "BBWS Cimanuk-Cisanggarung",
          position: [-6.73003, 108.54273],
        },
      ],
      wilKer: require("@/assets/geojson/administrasi.json"),
      sungaiUtama: require("@/assets/geojson/SUNGAI_CIMANCIS.json"),
      cisanggarung: require("@/assets/geojson/Cisanggarung_UTAMA.json"),
      cimanuk: require("@/assets/geojson/RISKCIMANUK.json"),
      forecast: null,
      isCardModalActive: false,
      registerId: null,
      baseUrl: "http://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0r.cgi",
      layers: [
        {
          name: "Weather Data",
          visible: true,
          format: "image/png",
          layers: "nexrad-n0r-900913",
          transparent: true,
          attribution: "Weather data © 2012 IEM Nexrad",
        },
      ],
      circle: {
        radius: 7,
        color: "#007bff",
        fillColor: "#007bff",
        fillOpacity: 50,
      },
      pch: {
        radius: 7,
        color: "#55ff00",
        fillColor: "#55ff00",
        fillOpacity: 50,
      },
      defaults: {
        radius: 7,
        color: "#bdc3c7",
        fillColor: "#bdc3c7",
        fillOpacity: 50,
      },

      pos: [],
      namaPos: "",
      jenis: null,
      forecastFiles: [],
      clusterFiles: [],
      clusterFile: null,
      keepFirst: false,
      openOnFocus: false,
      selected: null,
      clearable: false,
      isFetching: false,
      form: {
        kadaster: null,
      },
      showSidebar: false,
      position: [],
    };
  },
  async created() {
    this.fetchLandingForecasting().then((resp) => {
      resp.forEach((item) => {
        if (item !== null) {
          const forecastFile = item.forecastFile.fileName;
          fetch(`/api/landing/forecast/file/download/${forecastFile}`)
            .then((response) => response.json())
            .then((data) => {
              this.forecastFiles.push(data);
            });
          Promise.all(this.forecastFiles).then(function () {
            // console.log("print");
          });
        }
      });
    });
    this.fetchLandingKadaster().then((resp) => {
      resp.forEach((item) => {
        this.pos.push(item);
      });
    });
    this.fetchCluster().then((resp) => {
      resp.forEach((item) => {
        if (item !== null) {
          this.fileDownlaod(item).then((response) => {
            this.clusterFiles.push(response);
          });
        }
      });
    }),
      this.loadSubcribe();
     
  },
  computed: {
    ...mapState({
      kadasters: (state) => state.landingKadaster.items,
      landingForecasting: (state) => state.landingForecasting.items,
      landingCluster: (state) => state.landingCluster.items,
      landingClusterFile: (state) => state.landingClusterFile.item,
    }),

    styleFunction() {
      return () => {
        return {
          weight: 3,
          color: "#2980b9",
          opacity: 1,
          fillColor: "#c23616",
          fillOpacity: 1,
        };
      };
    },
    styleCisanggarung() {
      return () => {
        return {
          weight: 1,
          color: "#0652DD",
          opacity: 1,
          fillColor: "#c23616",
          fillOpacity: 1,
        };
      };
    },
    styleJangkelok() {
      return () => {
        return {
          weight: 3,
          color: "#EA2027",
          opacity: 1,
          fillColor: "#c23616",
          fillOpacity: 1,
        };
      };
    },
    styleWilker() {
      return () => {
        return {
          weight: 3,
          color: "#4b6584",
          opacity: 1,
          fillColor: "#c23616",
          fillOpacity: 1,
        };
      };
    },
    styleForecast() {
      return () => {
        return {
          weight: 3,
          color: "#5E2C0B",
          opacity: 1,
          fillColor: "#5E2C0B",
          fillOpacity: 1,
        };
      };
    },
    filteredPos() {
       
      return this.pos.filter((option) => {
        return (
          option.namaPos
            .toString()
            .toLowerCase()
            .indexOf(this.namaPos.toLowerCase()) >= 0
        );
      });
    },
  },
  methods: {
    cariData() {
      // console.log(this.namaPos);
      const params = [`namaPos=${this.namaPos}`];

      this.searchLandingKadaster(params).then((resp) => {
        resp.forEach((item) => {
          this.center = [item.lat, item.lng];
          this.newLoc = item.namaPos;
          this.newLt = item.lat;
          this.newLng = item.lng;
          this.registerId = item.registerId;
          // (this.zoom = 12),
          this.$refs.marker.mapObject.closeTooltip();
          this.$refs.marker.mapObject.toggleTooltip();
        });
        this.namaPos = "";
      });
    },
    lokasi() {
      this.fetchLandingKadaster();
    },
    showNav() {
      this.showSidebar = !this.showSidebar;
    },
    handleClick: function (event) {
      this.position = event;
    },
    ...mapActions("landingKadaster", [
      "fetchLandingKadaster",
      "searchLandingKadaster",
    ]),
    ...mapActions("landingForecasting", ["fetchLandingForecasting"]),
    ...mapActions("landingCluster", ["fetchCluster"]),
    ...mapActions("landingClusterFile", ["fileDownlaod"]),

    clickHandler() {
      window.alert("and mischievous");
    },
    displayTooltip(selectedIndex) {
      for (let markerObject of this.markerObjects) {
        markerObject.closeTooltip();
      }
      this.markerObjects[selectedIndex].toggleTooltip();
    },
    handleEmit(event) {
      this.$root.$emit("global-notification", event);
    },
    loadSubcribe() {
      sseClient = this.$sse.create({
        url: "/socket/clusters/subscribe",
        format: "json",
        withCredentials: false,
        polyfill: true,
      });

      // Catch any errors (ie. lost connections, etc.)
      sseClient.on("error", (e) => {
        console.error("lost connection or failed to parse!", e);

        // If this error is due to an unexpected disconnection, EventSource will
        // automatically attempt to reconnect indefinitely. You will _not_ need to
        // re-add your handlers.
      });

      // Handle messages without a specific event
      sseClient.on("message", this.message);

      // Handle 'chat' messages
      sseClient.on("clusters", (msg) => {
        if (msg.isActive) {
          this.fileDownlaod(msg).then((response) => {
            this.clusterFiles.push(response);
            // var audio = new Audio(sond.soundurl);
            // audio.play();
          });
        } else {
          this.clusterFiles.splice(this.clusterFiles.indexOf(msg.id), 1);
        }
      });

      sseClient
        .connect()
        .then((sse) => {
          console.log("Data Clusters Realtime connected!");

          // Unsubscribes from event-less messages after 7 seconds
          setTimeout(() => {
            sseClient.off("message", this.message);
            // console.log("Stopped listening to event-less messages!");
          }, 7000);

          // Unsubscribes from chat messages after 14 seconds
          setTimeout(() => {
            sse.off("clusters", this.data);
            // console.log("Stopped listening to Pda Monitoring messages!");
          }, 14000);
        })
        .catch((err) => {
          // When this error is caught, it means the initial connection to the
          // events server failed.  No automatic attempts to reconnect will be made.
          console.error("Failed to connect to server", err);
        });
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.marker.mapObject.toggleTooltip();
    });
    this.lokasi();
  },
  beforeDestroy() {
    // Make sure to close the connection with the events server
    // when the component is destroyed, or we'll have ghost connections!
    sseClient.disconnect();

    // Alternatively, we could have added the `sse: { cleanup: true }` option to our component,
    // and the SSEManager would have automatically disconnected during beforeDestroy.
  },
  watch: {
    position: {
      // the callback will be called immediately after the start of the observation
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        // console.log(val);
        // console.log(oldVal)
        if (val != "") {
          this.center = [val[0], val[1]];
          this.newLoc = val[2];
          this.newLt = val[0];
          this.newLng = val[1];
          this.registerId = val[3];
          this.$nextTick(() => {
            this.$refs.marker.mapObject.closeTooltip();
            this.$refs.marker.mapObject.toggleTooltip();
          });
        } else if (oldVal != "") {
          this.$nextTick(() => {
            this.$refs.marker.mapObject.closeTooltip();
            this.$refs.marker.mapObject.toggleTooltip();
          });
        }
      },
    },
  },
};
</script>
<style scoped>
.map {
  margin-top: 0px;
  width: 100%;
  height: 100vh;
  z-index: 1;
}

.nav {
  position: absolute;
  margin-top: 5px;
  margin-left: 10px;
  z-index: 999;
}

.informasi {
  position: absolute;
  top: 150px;
  right: 0px;
  z-index: 9;
}

button.lihat {
  background-color: #ed6755;
  border: none;
  border-radius: 5px;
  /* width: 50px; */
  padding: 14px;
  font-size: 16px;
  color: white;
  box-shadow: 0px 6px 18px -5px rgba(237, 103, 85, 1);
}

.title2 {
  font-size: 12px;
  font-weight: 500;
  margin-left: 10px;
  padding-top: 2px;
}

/* pencarian */

/* .controllayer {
  position: relative;
  margin-top: 10px;
  transition: 0.5s;
}

.pencarian {
  position: absolute;
  margin-top: 85px;
  margin-left: 85%;
  transition: 0.5s;
  z-index: 9;
}

.layersearch {
  position: relative;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 279px;
  height: 44px;
  transition: 0.5s;
  margin-right: 0px;
  border: 1px solid rgba(250, 249, 249, 0.451);
  box-shadow: 0 5px 15px rgba(95, 95, 95, 0.316);
  border-radius: 25px;
  background: #fff;
  z-index: 999;
}

.autocomplet {
  position: relative;
  margin-top: 10px;
  width: 225px;
  background: transparent;
  z-index: 999;
}

.cari {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-left: 10px;
}
 */

/* end */

/* animasi pencarian */

.animasi-pencarian {
  position: absolute;
  margin-top: 90px;
  right: 5px;
  transition: 0.5s;
  z-index: 9;
}
.search-box {
  position: relative;
  width: 45px;
  height: 45px;
  transition: all 1s ease-in-out;
  animation-delay: 1s;
}

.search-box.show {
  width: 350px;
  transition: all 1s ease-in-out;
  animation-delay: 1s;
}

.search-box .autocom.show {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  border-radius: 50px;
  font-size: 14px;
  padding: 0 60px 0 20px;
  background: #fff;
  transition: all 1s ease-in-out;
  animation-delay: 1s;
  opacity: 1;
}

.search-box .autocom {
  width: 100%;
  height: 100%;
  justify-content: center;
  border: none;
  outline: none;
  border-radius: 50px;
  font-size: 14px;
  padding: 0 50px 0 20px;
  background: #fff;
  transition: all 1s ease-in-out;
  animation-delay: 1s;
  opacity: 0;
}

.search-box .search-btn {
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  height: 40px;
  width: 40px;
  color: #fff;
  background: #212c5f;
  border: 1px solid #ffffff84;
  line-height: 40px;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  transition: all 1s ease-in-out;
}

.search-box .search-btn:hover {
  background: #2d6efd;
  transition: all 1s ease-in-out;
}

.search-box .search-btn i {
  font-size: 18px;
  font-weight: 600;
  transition: all 1s ease-in;
}
.search-box .search-btn i:hover {
  transform: rotate(360deg);
  transition: all 1s ease-out;
  font-size: 14px;
  font-weight: 700;
}

.search-box .medium.show {
  position: relative;
  padding-top: 4px;
  margin-left: -15px;
  width: 295px;
  transition: all 1s ease-in-out;
  animation-delay: 1s;
}

.caridata {
  margin-top: -46px;
  margin-left: 243px;
  width: 33px;
  height: 33px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
  border: 2px solid rgba(130, 129, 129, 0.631);
  border-radius: 50%;
  transition: all 1s ease-in-out;
  animation-delay: 1s;
}

.iconcari {
  font-size: 16px;
  font-weight: 700;
  color: gray;
}

/* end */

/* baru */

.lcontrol {
  display: flex;
  justify-content: space-evenly;
}

.layer1 {
  position: relative;
  width: 400px;
  height: 70px;
  backdrop-filter: blur(5px);
  background: rgba(255, 254, 254, 0.369);
  border: 1px solid rgba(233, 231, 231, 0.5);
  border-bottom: 1px solid rgba(255, 255, 255, 0.171);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
  border-radius: 0 0 10px 10px;
}

span {
  font-family: "Roboto", Arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
}

.cards {
  background: #212c5f;
  text-align: center;
}

.title1 {
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  padding-top: 10px;
  margin-left: 15px;
}

.biru {
  background-color: #2d6efd;
  width: 20px;
  height: 20px;
  margin-top: 5px;
  border-radius: 50px;
}

.hijau {
  background-color: rgb(150, 240, 15);
  width: 20px;
  height: 20px;
  margin-top: 5px;
  border-radius: 50px;
}

.sungai {
  background-color: #2d6efd;
  width: 40px;
  height: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 50px;
}

.merah {
  width: 40px;
  height: 4px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 50px;
  background: #fc0000ff;
}

/* end */

@media screen and (max-width: 1368px) {
  .pencarian {
    position: absolute;
    margin-top: 85px;
    margin-left: 78%;
    transition: 0.5s;
    z-index: 9;
  }
  .controllayer {
    position: relative;
    margin-left: 970px;
    transition: 0.5s;
  }
}

@media screen and (max-width: 1024px) {
  .nav {
    margin-top: 5px;
    margin-left: 4px;
    z-index: 9;
  }

  .pencarian {
    position: absolute;
    margin-top: 85px;
    margin-left: 71%;
    transition: 0.5s;
    z-index: 9;
  }

  .map {
    width: 100%;
    height: 85vh;
    z-index: 1;
  }

  .controllayer {
    margin-top: 140px;
    margin-left: 10px;
    width: 0px;
    transition: 0.5s;
  }

  .informasi {
    display: none;
  }
}

@media screen and (max-width: 768px) {
  .nav {
    margin-top: 5px;
    margin-left: 4px;
    z-index: 9;
  }
  .map {
    width: 100%;
    height: 85vh;
    z-index: 1;
  }

  .animasi-pencarian {
    position: absolute;
    margin-top: 140px;
    right: 5px;
    transition: all 5s cubic-bezier(0.1, 0.7, 1, 0.1);
    animation-delay: 5s;
    z-index: 2;
  }
  .layersearch {
    margin-top: 50px;
    margin-right: 30px;
  }

  .pencarian {
    margin-left: 19%;
    z-index: 5;
  }

  .autocomplet {
    position: relative;
    margin-top: 10px;
  }

  .cari {
    width: 35px;
    height: 35px;
    margin-top: 0px;
    border-radius: 50%;
    margin-left: 10px;
  }
  .box {
    width: 70%;
  }

  .layer1 {
    position: relative;
    width: 340px;
    height: 75px;
    backdrop-filter: blur(5px);
    background: rgba(255, 254, 254, 0.369);
    border: 1px solid rgba(233, 231, 231, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.171);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.109);
    border-radius: 0 0 10px 10px;
    transition: 0.5s;
  }

  span {
    font-family: "Roboto", Arial, sans-serif;
    font-size: 10px;
    font-weight: 500;
  }

  .biru {
    background-color: #0e6ad3;
    width: 15px;
    height: 15px;
    margin-top: 5px;
    border-radius: 50px;
  }

  .hijau {
    background-color: rgb(150, 240, 15);
    width: 15px;
    height: 15px;
    margin-top: 5px;
    border-radius: 50px;
  }

  .sungai {
    background-color: #2d6efd;
    width: 40px;
    height: 4px;
    margin-top: 10px;
    margin-bottom: 6px;
    border-radius: 50px;
  }

  .merah {
    width: 40px;
    height: 4px;
    margin-top: 10px;
    margin-bottom: 6px;
    border-radius: 50px;
    background: #fc0000ff;
  }

  .header {
    display: flex;
    flex-direction: column;
  }

  .informasi {
    display: none;
  }
}
</style>
